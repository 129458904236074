import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"

import componentStyles from "./press.module.scss"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"

const mediaItems = [
  {
    name: "logo-svg",
    path: "/images/inventale-logo.svg",
    thumbnail: "/images/inventale-logo.svg",
    description: "inventale-logo.svg",
  },
  {
    name: "logo-png",
    path: "/images/inventale-logo.png",
    thumbnail: "/images/inventale-logo.png",
    description: "inventale-logo.png (700px x 156px)",
  },
]

const Press: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()

  const pressClasses = classNames(componentStyles.press, "container")
  const pressTitleClasses = classNames("large-section-title")
  const pressMediaClasses = classNames(componentStyles.pressList, "grid-3")
  const pressThumbnailWrapperClasses = classNames(
    componentStyles.pressThumbnailWrapper,
    "bordered-picture"
  )

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({ id: "press.seo.description" })}
        title={intl.formatMessage({ id: "press.seo.title" })}
        pathname={location.pathname}
      />
      <section className={pressClasses}>
        <h1 className={pressTitleClasses}>
          {intl.formatMessage({ id: "press.title" })}
        </h1>
        <div className={pressMediaClasses}>
          {mediaItems.map((mediaItem, index) => (
            <a
              className={componentStyles.pressLink}
              href={mediaItem.path}
              download
              key={index}
            >
              <figure className={componentStyles.pressFigure}>
                <div className={pressThumbnailWrapperClasses}>
                  <img
                    className={componentStyles.pressThumbnail}
                    src={mediaItem.thumbnail}
                    alt={mediaItem.description}
                    width="275"
                    height="158"
                  />
                </div>
                <figcaption className={componentStyles.pressFigureDescription}>
                  {mediaItem.description}
                </figcaption>
              </figure>
            </a>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default Press
